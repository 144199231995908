import { createTheme, ThemeOptions } from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import { MuiAppBar, MuiAutocomplete, MuiButton, MuiChip, MuiDataGrid, MuiInputLabel, MuiTypography } from './components'
import { MuiDialog } from "./components/MuiDialog";
import type {} from '@mui/x-data-grid/themeAugmentation'

const DefaultTheme: ThemeOptions = {
  palette,
  typography,
  components: {
    MuiAppBar,
    MuiAutocomplete,
    MuiButton,
    MuiChip,
    MuiDataGrid,
    MuiInputLabel,
    MuiTypography,
    MuiDialog,
  },
};

export default createTheme(DefaultTheme)
