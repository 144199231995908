import { primary } from "../colors";

export const MuiDialog = {
  styleOverrides: {
    root: {
      "& .MuiDialogTitle-root": {
        backgroundColor: primary[700],
        color: "white",
      },
      "& .MuiIconButton-root": { color: "white" },
    },
  },
};
