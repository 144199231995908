import { PaletteColorOptions } from '@mui/material';

export const primary: PaletteColorOptions = {
  25: '#F8F9FB',
  50: '#EEF0F6',
  100: '#E1E4EF',
  200: '#CFD2E7',
  300: '#989FCD',
  400: '#7278B6',
  500: '#50579B',
  600: '#3F437D',
  700: '#333666',
  800: '#292757',
  900: '#1D1746',
  get main() {
    return this[700]
  },
  contrastText: '#fff'
}
