import { Box, Button } from '@mui/material'

const PrimaryDesktopMenu = ({ pages, onNavClose }) => {
  return (
    <Box sx={{ flexGrow: 1, gap: 1, display: { xs: 'none', md: 'flex' } }}>
      {pages.map((page, index) => (
        <Button
          key={`desktop-${index}`}
          onClick={onNavClose}
          variant="text"
          color="slate"
          sx={{ my: 2, display: 'block' }}
        >
          {page}
        </Button>
      ))}
    </Box>
  )
}

export default PrimaryDesktopMenu
