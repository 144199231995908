import { slate } from '../colors';

export const MuiAutocomplete = {
  styleOverrides: {
    root: {
      '& .MuiInputBase-root.MuiInputBase-sizeSmall': {
        border: `1px solid ${slate[200]}`,
        borderRadius: 8,
      }
    }
  }
}
