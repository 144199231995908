import { PaletteColorOptions } from '@mui/material';

export const success: PaletteColorOptions = {
  25: '#F6FEF9',
  50: '#ECFDF3',
  100: '#D1FADF',
  200: '#A6F4C5',
  300: '#6CE9A6',
  400: '#32D583',
  500: '#12B76A',
  600: '#039855',
  700: '#027A48',
  800: '#05603A',
  900: '#054F31',
  get main() {
    return this[50]
  },
  get contrastText () {
    return this[700]
  }
}
