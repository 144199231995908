import { ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { NextPage } from 'next'
import { ReactElement, ReactNode, useMemo, useState } from 'react'
import createEmotionCache from '../client/emotionCache'
import DefaultLayout from '../components/layouts/Default'
import DefaultTheme from '../styles/themes/default/theme'
import { $LayoutProps } from 'src/components/layouts/types'
import { UserProvider } from '@auth0/nextjs-auth0'
import AuthLink from 'src/components/user/AuthLink'
import Link from 'next/link'
import { Link as MLink } from '@mui/material'

type NextPageLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}
type AppLayoutProps = AppProps & {
  Component: NextPageLayout
}
const clientSideCache = createEmotionCache()

const App = ({ Component, pageProps }) => {
  // @todo: abstract into context for global dark/light mode toggle.
  const [mode] = useState<string>('light')
  const defaultLayoutProps: any = {
    pages: [
      <Link key={0} href="/">
        <MLink variant="button" underline="none">Home</MLink>
      </Link>,
      <Link key={1} href="/blog">
        <MLink variant="button" underline="none">Blog Library</MLink>
      </Link>
    ],
    settings: [
      <AuthLink key={2} />
    ]
  }
  const [layoutProps, setLayoutProps] = useState<$LayoutProps|null>(defaultLayoutProps)
  // Check for component-specific layout or use default.
  const theme = useMemo(() => DefaultTheme, [mode])
  const Layout = (page, user) =>
    <DefaultLayout {...layoutProps} user={user}>{page}</DefaultLayout>
  const getLayout = Component.getLayout ?? Layout

  return (
    <UserProvider>
      <CacheProvider value={clientSideCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={theme}>
          {getLayout(<Component {...pageProps} />)}
        </ThemeProvider>
      </CacheProvider>
    </UserProvider>
  )
}

export default App
