import { FC } from 'react'
import { useUser } from '@auth0/nextjs-auth0'
import Link from 'next/link'
import { Link as MLink } from '@mui/material'

const AuthLink: FC = () => {
  const { error, isLoading, user } = useUser()
  if (isLoading) return <span>Loading...</span>
  if (error) return <span>{error.message}</span>
  return user ?
    <Link href="/api/auth/logout">
      <MLink variant="body2" underline="none">Logout</MLink>
    </Link> :
    <Link href="/api/auth/login">
      <MLink variant="body2" underline="none">Login</MLink>
    </Link>
}

export default AuthLink
