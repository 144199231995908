declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    light: true;
    slate: true;
  }
}
export const MuiButton = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
    sizeMedium: {
      fontSize: 14
    },
    textSlate: {
      '&:hover': {
        backgroundColor: '#f9fafb'
      }
    }
  }
}
