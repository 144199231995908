import { primary } from '../colors'

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    light: true;
    slate: true
  }
  interface ChipPropsVariantOverrides {
    inverse: true
  }
}
export const MuiChip = {
  styleOverrides: {
    root: {
      fontWeight: 500
    },
    inverse: {
      backgroundColor: primary[50],
      color: primary[700],
      fontWeight: 500,
      '& .MuiSvgIcon-root': {
        color: primary[700]
      }
    }
  }
}
