import { slate } from '../colors'
export const MuiTypography = {
  styleOverrides: {
    root: {
      fontFamily: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    subtitle1: {
      color: slate[500]
    }
  }
}
