import { PaletteColorOptions } from '@mui/material'

export const slate: PaletteColorOptions = {
  25: '#FCFCFD',
  50: '#f9FAFB',
  100: '#F2F4F7',
  200: '#E4E7EC',
  300: '#D9D5DD',
  500: '#667085',
  600: '#475467',
  700: '#344054',
  800: '#1D2939',
  900: '#101828',
  get main () {
    return this[700]
  }
}
