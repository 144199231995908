import { useUser } from '@auth0/nextjs-auth0'
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { $LinkProps } from '../layouts/types'
import Link from '../links/Link'

interface $UserProps {
  name: string
  image: string | null
}

interface $UserMenuProps {
  settings?: $LinkProps[],
  isAuthenticated: boolean,
  user?: $UserProps
}
const UserMenu = (props: $UserMenuProps) => {
  const { user } = useUser()
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const { settings = [] } = props
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open user menu">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={user?.name || 'Guest'} src={user?.picture || null} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="user-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {Array.isArray(settings) && settings.map(({ label, href }, index) => (
          <MenuItem key={href + label} onClick={handleCloseUserMenu}>
            <Link underline="none" href={href || ''}>{label}</Link>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default UserMenu
