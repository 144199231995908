import { slate } from '../colors';

export const MuiInputLabel = {
  styleOverrides: {
    root: {
      fontWeight: 500,
      fontSize: 14,
      color: slate[700]
    }
  }
}
