import { PaletteColorOptions } from '@mui/material';

export const warning: PaletteColorOptions = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC8603',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
  get main() {
    return this[50]
  },
  get contrastText () {
    return this[700]
  }
}
