import { grey } from '@mui/material/colors'
import { slate } from '../colors'

export const MuiDataGrid = {
  styleOverrides: {
    root: {
      color: slate[500],
    },
    row: {
      '&:hover': {
        backgroundColor: slate[50]
      }
    },
    cell: {
      '&:focus': {
        outline: 'none'
      },
      '&:first-of-type': {
        fontWeight: 700
      }
    },
    columnSeparator: {
      display: 'none'
    },
    columnHeader: {
      '&:focus': {
        outline: 'none'
      }
    },
    columnHeaders: {
      backgroundColor: grey[100],
      color: slate[500],
      fontWeight: 500,
      borderBottom: `1px solid ${grey[300]}`
    }
  }
}
