import NLink from "next/link";
import { Link as MLink } from "@mui/material";
const Link = (props) => {
  const { children, href, variant, ...rest } = props
  return (
    <NLink href={props.href} passHref>
      <MLink variant={props.variant || 'button'} {...rest}>{props.children}</MLink>
    </NLink>
  )
}

export default Link
