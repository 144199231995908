import { PaletteColor, PaletteColorOptions, PaletteOptions } from '@mui/material'
import { primary, slate, success, warning } from './colors'

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    light: PaletteColor,
    slate: PaletteColorOptions,
  }
}

declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    25?: string
  }
  interface Color {
    25?: string
  }
}

const palette: PaletteOptions = {
  background: {
    // default: '#F9FAFB'
  },
  light: {
    light: '#cccccc',
    main: "#fff",
    dark: '#212121',
    contrastText: '#212121'
  },
  primary,
  slate,
  success,
  warning
}

export default palette
