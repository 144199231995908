import { AppBar, Box, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useState } from 'react'
import PrimaryDesktopMenu from '../menus/PrimaryDesktop'
import Image from 'next/image'
import logo from '../../client/images/logo.svg'
import UserMenu from '../menus/UserMenu'
import { $LayoutProps } from './types'
import { withPageAuthRequired } from '@auth0/nextjs-auth0'

/**
 * Temporarily protect any view using default layout.
 *
 * @todo Remove `withPageAuthRequired`
 */
export default withPageAuthRequired(function DefaultLayout (props: $LayoutProps) {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const { pages = [], settings = [] } = props

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <>
      <AppBar
        position="static"
        color="light"
        variant="elevation"
        elevation={0}
        sx={{ borderBottom: '1px solid #ccc' }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Desktop logo display */}
            <Box
              sx={{ mr: 4, display: { xs: 'none', md: 'flex' } }}
            >
              <Image
                src={logo}
                width={172}
                height={40}
                alt="Elm Street Logo"
              />
            </Box>
            {/* mobile display - links. */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.length && pages.map((page, index) => (
                  <MenuItem key={`mobile-menu-${index}`} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' }
              }}
            >
              <Image
                src={logo}
                height={50}
                alt="Elm Street Logo"
              />
            </Box>
            <PrimaryDesktopMenu pages={pages} onNavClose={handleCloseNavMenu} />
            <UserMenu
              isAuthenticated={false}
              user={{ name: 'Guest', image: null }}
              settings={[{ label: 'Logout', href: '/api/auth/logout' }]}
            />
          </Toolbar>
        </Container>
      </AppBar>
      <Container>
        {props.children}
      </Container>
    </>
  )
})

// export default DefaultLayout
